const configs = {

    dev: {
        api: {
            host: "https://stemitdev.com",
            port: "5001",
            prefix: "/api",
        },
    },

    staging: {
        api: {
            host: "https://stemitdev.com",
            port: "5001",
            prefix: "",
        },
    },

    production: {
        api: {
            host: "https://api.stemit.com",
            port: "5001",
            prefix: "/api",
       },
    },
};

const config = configs.staging;

module.exports = config;
